<template>
  <div
    :class="topCss"
    ontouchstart=""
    style="width: 100%; height: 100%"
  >
    <div
      v-show="pageProgress"
      id="page-progress"
      class="page-progress mdl-js-progress"
    />
    <div
      id="wrapper"
      class="base-layout mdl-js-layout"
    >
      <component
        :is="headerComponent"
        v-if="headerComponent"
      />
      <header v-if="!headerComponent">
        <div
          class="mdl-layout__header-row"
        >
          <div
            :data-badge="badgeMobile"
            class="nav badge mdl-badge mdl-badge--overlap"
          />
          <div
            v-cloak
            class="title header"
          >
            <i
              v-if="isPinned"
              class="fas fa-thumbtack fcGray fs14"
            />
            {{ title }}
          </div>
          <div class="mdl-layout-spacer" />
          <aui-button
            v-show="headerSubmenu != ''"
            id="header-submenu-button"
            class="ml5 icon"
            style="display:none"
            aria-label="ルーム設定"
          >
            <i class="fas fa-ellipsis-h" />
          </aui-button>
          <div
            v-show="headerSubmenu != ''"
            v-html="headerSubmenu"
          />
        </div>
      </header>
      <div class="rightDrawer mdl-layout__drawer is-right">
        <div class="mdl-layout-spacer" />
      </div>
      <div class="drawer mdl-layout__drawer">
        <nav v-show="drawer == 'system'">
          <div class="logo">
            <div
              id="menu-reload"
              @click="onClickLogo"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 93 113.1"
              >
                <g data-name="symbol">
                  <path d="M62.6,112.4c3.1-0.5,6.1-1.3,9-2.4c5.1-1.9,9.9-4.7,14.2-8.3c-2.8-29-33.2-43.4-33.2-43.5c-0.3-0.2-0.6-0.5-0.7-0.8 c-0.3-0.8,0.1-1.6,0.9-1.8c17-6,16.5-14.5,15.7-17.6L7.3,59.5C8,62.1,10,68.1,14.1,68c0.9-0.1,1.9-0.2,2.8-0.3c1-0.1,2-0.1,2.9-0.1 c11.8,0,22.6,4.8,30.3,12.6c7.8,7.8,12.6,18.5,12.6,30.3C62.7,111.1,62.6,111.7,62.6,112.4L62.6,112.4z" /> <path d="M29.4,105.9c3.4,2.1,7,3.7,10.7,4.9c4.7,1.5,9.6,2.3,14.7,2.3c1.7,0,3.3-0.1,5-0.3c0-0.2,0-0.3,0-0.5c0-0.5,0-1.1,0-1.8 c0-11-4.5-21-11.7-28.3c-3.2-3.2-7-5.9-11.1-7.9c0.3,0.4,0.6,0.8,0.9,1.2c2.6,3.4,4,7.5,4,12c0,4.7-1.6,9-4.4,12.4 C35.4,102.5,32.6,104.6,29.4,105.9" /> <path
                    class="st1"
                    d="M40.4,39l5.1-6.1c1.2-1.4,2.3-3,3.1-4.7c1.7-3.4,2.6-7.1,2.6-10.9c0-2.8-0.5-5.5-1.4-8.1C49,7,47.9,5,46.6,3.1 l-0.3-0.5l2.6-1.4l0,0l1.4-0.7l0.3,0.4c1.6,2.3,2.8,4.7,3.7,7.4c1,2.9,1.5,6,1.5,9.1c0,6.2-2,12.2-5.7,17.2c-3.7,5-9,8.6-14.9,10.4 c-1.3,0.4-2.7,0.7-4,0.9c-1.4,0.2-2.7,0.3-4.1,0.3l-0.5,0c-0.2,0-0.4,0-0.5,0c-0.2,0-0.5,0-0.7,0c-0.2,0-0.5,0-0.7,0 c-0.8-0.1-1.6-0.2-2.4-0.3c-0.8-0.1-1.6-0.3-2.4-0.5C15.1,44,11,41.8,7.5,38.6c-3.2-2.9-5.7-6.5-7.3-10.5L0,27.7l4-2.2l0.2,0.5 c1.4,3.6,3.7,6.9,6.6,9.4c2.9,2.6,6.4,4.4,10.1,5.4l1.9,0.5l0.6-1.8c0.3-1,0.6-2,0.8-3c0.3-1.5,0.4-3,0.4-4.5 c0-2.8-0.5-5.6-1.4-8.2c-0.8-2.2-1.9-4.3-3.3-6.2l-0.4-0.5l2.6-1.4l0,0l1.4-0.7l0.3,0.4c1.6,2.3,2.9,4.8,3.8,7.5 c1,3,1.5,6.1,1.5,9.2c0,1.5-0.1,3-0.3,4.4c-0.1,0.8-0.3,1.6-0.5,2.3l-0.7,3l3.1-0.5c0.3-0.1,0.7-0.1,1-0.2l0.8-0.2l0.5-0.6 c1.6-2,2.8-4.3,3.7-6.7c0.9-2.6,1.4-5.3,1.4-8.1c0-2.9-0.5-5.8-1.5-8.5c-0.9-2.3-2.1-4.5-3.6-6.4l-0.4-0.5L35,8.7l0,0l1.4-0.8 l0.3,0.4c1.8,2.3,3.1,4.9,4.1,7.7c1.1,3.1,1.6,6.3,1.6,9.5c0,1.8-0.2,3.6-0.5,5.4L40.4,39z"
                  />
                </g>
              </svg>
            </div>
          </div>
          <div class="profile">
            <div class="avatar">
              <img
                :src="profileImageUrl"
                :alt="name"
                width="32"
                height="32"
              >
            </div>
            <div class="name">
              {{ name }}
            </div>
            <div
              :data-badge="badge.activity"
              class="mr0 badge mdl-badge mdl-badge--overlap"
            >
              <aui-button
                class="notice icon"
                @click.native="onClickActivity"
              >
                <i class="far fa-bell" />
              </aui-button>
            </div>
            <aui-button
              class="settings icon"
              @click.native="onClickSettings"
            >
              <i class="fas fa-cog fa-fw" />
            </aui-button>
          </div>
          <hr>
          <a
            v-show="activeApps.includes('Schedule')"
            v-cloak
            id="menu-calendar"
            :href="contextPath + '/apps/calendar'"
            :class="{ active: active == 'Schedule'}"
            :style="'order: ' + activeApps.indexOf('Schedule')"
            class="mdl-navigation__link mdl-js-button"
          ><div class="badge mdl-badge mdl-badge--overlap"><i class="far fa-calendar-alt fa-fw" /></div><label>カレンダー</label></a>
          <a
            v-show="activeApps.includes('Schedule') && activeApps.includes('AddOnScheduling')"
            v-cloak
            id="menu-scheduling-vote"
            :href="contextPath + '/apps/calendar/scheduling/votes'"
            :class="{ active: active == 'SchedulingVote'}"
            :style="'order: ' + activeApps.indexOf('Scheduling')"
            class="mdl-navigation__link mdl-js-button"
          ><div
            :data-badge="badge.scheduling"
            class="badge mdl-badge mdl-badge--overlap"
          ><i class="far fa-calendar-check fa-fw" /></div><label>出欠確認</label></a>
          <a
            v-show="activeApps.includes('Schedule') && activeApps.includes('AddOnScheduling')"
            v-cloak
            id="menu-scheduling"
            :href="contextPath + '/apps/calendar/scheduling'"
            :class="{ active: active == 'Scheduling'}"
            :style="'order: ' + activeApps.indexOf('Scheduling')"
            class="mdl-navigation__link mdl-js-button"
          ><div
            class="badge mdl-badge mdl-badge--overlap"
          ><i class="fas fa-user-clock" /></div><label>日程調整</label></a>
          <a
            v-show="activeApps.includes('Message')"
            v-cloak
            id="menu-chat"
            :href="contextPath + '/apps/chat'"
            :class="{ active: active == 'Message'}"
            :style="'order: ' + activeApps.indexOf('Message')"
            class="mdl-navigation__link mdl-js-button"
          ><div
            :data-badge="badge.chat"
            class="badge mdl-badge mdl-badge--overlap"
          ><i class="far fa-comment fa-fw" /></div><label>チャット</label></a>
          <a
            v-show="activeApps.includes('Timeline')"
            v-cloak
            id="menu-timeline"
            :href="contextPath + '/apps/timeline'"
            :class="{ active: active == 'Timeline'}"
            :style="'order: ' + activeApps.indexOf('Timeline')"
            class="mdl-navigation__link mdl-js-button"
          ><div
            :data-badge="badge.timeline"
            class="badge mini  mdl-badge mdl-badge--overlap"
          ><i class="fas fa-history fa-fw" /></div><label>タイムライン</label></a>
          <a
            v-show="activeApps.includes('Msgboard')"
            v-cloak
            id="menu-bbs"
            :href="contextPath + '/apps/bbs'"
            :class="{ active: active == 'Msgboard'}"
            :style="'order: ' + activeApps.indexOf('Msgboard')"
            class="mdl-navigation__link mdl-js-button"
          ><div
            :data-badge="badge.msgboard"
            class="badge mini mdl-badge mdl-badge--overlap"
          ><i class="far fa-clipboard fa-fw" /></div><label>掲示板</label></a>
          <a
            v-show="activeApps.includes('ToDo')"
            v-cloak
            id="menu-todo"
            :href="contextPath + '/apps/todo'"
            :class="{ active: active == 'ToDo'}"
            :style="'order: ' + activeApps.indexOf('ToDo')"
            class="mdl-navigation__link mdl-js-button"
          ><div class="badge  mdl-badge mdl-badge--overlap"><i class="far fa-check-square fa-fw" /></div><label>ToDo</label></a>
          <a
            v-show="activeApps.includes('ExtTimecard')"
            v-cloak
            id="menu-timecard"
            :href="contextPath + '/apps/timecard'"
            :class="{ active: active == 'ExtTimecard'}"
            :style="'order: ' + activeApps.indexOf('ExtTimecard')"
            class="mdl-navigation__link mdl-js-button"
          ><div class="badge  mdl-badge mdl-badge--overlap"><i class="far fa-clock fa-fw" /></div><label>タイムカード</label></a>
          <a
            v-show="activeApps.includes('Workflow')"
            v-cloak
            id="menu-workflow"
            :href="contextPath + '/apps/workflow'"
            :class="{ active: active == 'Workflow'}"
            :style="'order: ' + activeApps.indexOf('Workflow')"
            class="mdl-navigation__link mdl-js-button"
          ><div
            :data-badge="badge.workflow"
            class="badge  mdl-badge mdl-badge--overlap"
          ><i class="fas fa-sitemap fa-fw" /></div><label>ワークフロー</label></a>
          <a
            v-show="activeApps.includes('Cabinet')"
            v-cloak
            id="menu-folder"
            :href="contextPath + '/apps/folder'"
            :class="{ active: active == 'Cabinet'}"
            :style="'order: ' + activeApps.indexOf('Cabinet')"
            class="mdl-navigation__link mdl-js-button"
          ><div
            :data-badge="badge.cabinet"
            class="badge mini mdl-badge mdl-badge--overlap"
          ><i class="fas fa-folder-open fa-fw" /></div><label>フォルダ</label></a>
          <a
            v-show="activeApps.includes('Note')"
            v-cloak
            id="menu-note"
            :href="contextPath + '/apps/phonememo'"
            :class="{ active: active == 'Note'}"
            :style="'order: ' + activeApps.indexOf('Note')"
            class="mdl-navigation__link mdl-js-button"
          ><div
            :data-badge="badge.note"
            class="badge mini mdl-badge mdl-badge--overlap"
          ><i class="fas fa-phone-square fa-fw" /></div><label>伝言メモ</label></a>
          <a
            v-show="activeApps.includes('WebMail')"
            v-cloak
            id="menu-webmail"
            :href="contextPath + '/apps/webmail'"
            :class="{ active: active == 'WebMail'}"
            :style="'order: ' + activeApps.indexOf('WebMail')"
            class="mdl-navigation__link mdl-js-button"
          ><div class="badge  mdl-badge mdl-badge--overlap"><i class="far fa-envelope fa-fw" /></div><label>Webメール</label></a>
          <a
            v-show="activeApps.includes('AddressBook')"
            v-cloak
            id="menu-addressbook"
            :href="contextPath + '/apps/addressbook'"
            :class="{ active: active == 'AddressBook'}"
            :style="'order: ' + activeApps.indexOf('AddressBook')"
            class="mdl-navigation__link mdl-js-button"
          ><div class="badge  mdl-badge mdl-badge--overlap"><i class="far fa-address-card fa-fw" /></div><label>アドレス帳</label></a>
          <a
            v-show="activeApps.includes('Blog')"
            v-cloak
            id="menu-blog"
            :href="contextPath + '/apps/blog'"
            :class="{ active: active == 'Blog'}"
            :style="'order: ' + activeApps.indexOf('Blog')"
            class="mdl-navigation__link mdl-js-button"
          ><div class="badge  mdl-badge mdl-badge--overlap"><i class="fas fa-book fa-fw" /></div><label>ブログ</label></a>
          <a
            v-show="activeApps.includes('Report')"
            v-cloak
            id="menu-report"
            :href="contextPath + '/apps/report'"
            :class="{ active: active == 'Report'}"
            :style="'order: ' + activeApps.indexOf('Report')"
            class="mdl-navigation__link mdl-js-button"
          ><div
            :data-badge="badge.report"
            class="badge mini mdl-badge mdl-badge--overlap"
          ><i class="far fa-copy fa-fw" /></div><label>報告書</label></a>
          <a
            v-show="activeApps.includes('Project')"
            v-cloak
            id="menu-project"
            :href="contextPath + '/apps/project'"
            :class="{ active: active == 'Project'}"
            :style="'order: ' + activeApps.indexOf('Project')"
            class="mdl-navigation__link mdl-js-button"
          ><div class="badge  mdl-badge mdl-badge--overlap"><i class="fas fa-tasks fa-fw" /></div><label>プロジェクト</label></a>
          <a
            v-show="activeApps.includes('Gpdb')"
            v-cloak
            id="menu-webdb"
            :href="contextPath + '/apps/webdb'"
            :class="{ active: active == 'Gpdb'}"
            :style="'order: ' + activeApps.indexOf('Gpdb')"
            class="mdl-navigation__link mdl-js-button"
          ><div class="badge  mdl-badge mdl-badge--overlap"><i class="fas fa-database fa-fw" /></div><label>Webデータベース</label></a>
          <a
            v-show="activeApps.includes('Wiki')"
            v-cloak
            id="menu-wiki"
            :href="contextPath + '/apps/wiki'"
            :class="{ active: active == 'Wiki'}"
            :style="'order: ' + activeApps.indexOf('Wiki')"
            class="mdl-navigation__link mdl-js-button"
          ><div
            :data-badge="badge.wiki"
            class="badge mini mdl-badge mdl-badge--overlap"
          ><i class="fas fa-pen-square fa-fw" /></div><label>Wiki</label></a>
          <a
            v-show="activeApps.includes('Equipment')"
            v-cloak
            id="menu-equipment"
            :href="contextPath + '/apps/equipment'"
            :class="{ active: active == 'Equipment'}"
            :style="'order: ' + activeApps.indexOf('Equipment')"
            class="mdl-navigation__link mdl-js-button"
          ><div
            :data-badge="badge.equipment"
            class="badge mini mdl-badge mdl-badge--overlap"
          ><i class="fas fa-archive fa-fw" /></div><label>備品</label></a>
          <a
            v-show="activeApps.includes('Safety')"
            v-cloak
            id="menu-safety"
            :href="contextPath + '/apps/safety'"
            :class="{ active: active == 'Safety'}"
            :style="'order: ' + activeApps.indexOf('Safety')"
            class="mdl-navigation__link mdl-js-button"
          ><div class="badge  mdl-badge mdl-badge--overlap"><i class="fas fa-medkit fa-fw" /></div><label>安否確認</label></a>
          <a
            v-show="viewUserList"
            v-cloak
            id="menu-userlist"
            :href="contextPath + '/apps/userlist'"
            :class="{ active: active == 'UserList'}"
            style="order: 1000"
            class="mdl-navigation__link mdl-js-button"
          ><div class="badge mdl-badge mdl-badge--overlap"><i class="fas fa-users fa-fw" /></div><label>ユーザー名簿</label></a>
          <hr style="order: 1000">
          <a
            v-show="isPresentStatusValid && isNotificationActive"
            style="order: 1000"
            class="mdl-navigation__link mdl-js-button"
            href="javascript:void(0);"
            @click="onClickChangePresence"
          ><div class="badge mdl-badge mdl-badge--overlap"><i class="fa fa-walking fa-fw" /></div><label>ステータス変更</label></a>
          <a
            v-show="isNotificationActive"
            style="order: 1000"
            class="mdl-navigation__link mdl-js-button"
            href="javascript:void(0);"
            onclick="aipo.notification.openDialog();"
          ><div class="badge mdl-badge mdl-badge--overlap"><i class="far fa-bell fa-fw" /></div><label>通知設定</label></a>
          <a
            v-show="isAdmin"
            :href="contextPath + '/portal/admin/'"
            style="order: 1000"
            class="mdl-navigation__link mdl-js-button"
          ><div class="badge mdl-badge mdl-badge--overlap"><i class="fas fa-cogs fa-fw" /></div><label>管理設定</label></a>
          <a
            style="order: 1000"
            class="mdl-navigation__link mdl-js-button"
            href="https://kurojica.com/schedule/category/update/"
            rel="noopener"
            target="_blank"
          ><div class="badge mdl-badge mdl-badge--overlap"><i class="fas fa-info-circle" /></div><label>アップデート情報</label><small><i class="fas fa-external-link-alt fcGray ml5" /></small></a>
          <a
            style="order: 1000"
            class="mdl-navigation__link mdl-js-button"
            href="https://kurojica.tayori.com/q/schedule-help/"
            rel="noopener"
            target="_blank"
          ><div class="badge mdl-badge mdl-badge--overlap"><i class="fas fa-question-circle fa-fw" /></div><label>ヘルプ</label><small><i
            class="fas fa-external-link-alt fcGray ml5"
          /></small></a>
          <a
            v-show="viewSupport"
            style="order: 1000"
            class="mdl-navigation__link mdl-js-button"
            href="javascript:void(0);"
            onclick="ChannelIO('showMessenger');"
          ><div
            :data-badge="channelIOUnreadCount"
            class="badge  mdl-badge mdl-badge--overlap"
          ><i class="far fa-question-circle fa-fw" /></div><label>チャットサポート</label></a>
          <a
            style="order: 1000"
            class="mdl-navigation__link mdl-js-button"
            href="javascript:void(0)"
            @click="onClickLogout"
          ><div class="badge  mdl-badge mdl-badge--overlap"><i class="fas fa-power-off fa-fw" /></div><label>ログアウト</label></a>
        </nav>
        <div
          v-show="drawer == 'activity'"
          id="activityListPane"
          class="activity"
        >
          <div
            id="indicator-activityListPane"
            class="spinner-container center"
          >
            <span class="aui-spinner mini is-active" />
          </div>
        </div>
        <div class="mdl-layout-spacer" />
      </div>
      <div
        role="button"
        tabindex="0"
        class="back-button"
      >
        <i
          class="material-icons"
          onclick="App.back()"
        >arrow_back</i>
      </div>
      <keep-alive>
        <component
          :is="currentContent"
          ref="sub"
          :params="currentParams"
        />
      </keep-alive>
      <div
        class="mdl-layout__obfuscator is-right"
        @click="closeRightDrawer"
      />
      <div
        v-show="fab != '' && topCss == ''"
        class="fab-container animated zoomIn"
        style="display:none"
      >
        <aui-button
          :onclick="fab"
          class="fab"
        >
          <i class="fas fa-pencil-alt" />
        </aui-button>
      </div>
    </div>
    <div
      aria-live="assertive"
      aria-atomic="true"
      aria-relevant="text"
      class="mdl-snackbar mdl-js-snackbar"
    >
      <div class="mdl-snackbar__text" />
      <button
        type="button"
        class="mdl-snackbar__action"
      />
    </div>
    <iframe
      id="sharedStateIframe"
      name="sharedStateIframe"
      src="about:blank"
      frameborder="no"
      scrolling="no"
      width="0"
      height="0"
    />
    <iframe
      id="socketIframe"
      name="socketIframe"
      src="about:blank"
      frameborder="no"
      scrolling="no"
      width="0"
      height="0"
    />
    <aui-modal
      :component="modal.component"
      :params="modal.params"
      :width="modal.width"
      @close="onCloseModal"
    />
    <div
      id="dummyText"
      style="display:none"
    />
    <component
      :is="lazyContent"
      v-if="lazyContent"
    />
  </div>
</template>

<script>
import forEach from 'lodash/forEach';
import { select, selectAll } from 'd3-selection';
import { timeout } from 'd3-timer';

export default {
  data() {
    return {
      drawer: 'system',
    };
  },
  computed: {
    pageProgress() {
      return this.$store.state.workspace.pageProgress;
    },
    title() {
      const stitle = this.$store.state.workspace.subtitle;
      if (stitle) {
        return stitle;
      }
      return this.$store.state.workspace.title;
    },
    isPinned() {
      const stitle = this.$store.state.workspace.subtitle;
      if (stitle) {
        // subtitleが有効=ルームを表示している時のみisPinned情報を取得する
        const { isPinned } = this.$store.state.workspace;
        return isPinned;
      }
      return false;
    },
    contextPath() {
      return this.$store.state.workspace.contextPath;
    },
    settingsPortletId() {
      return this.$store.state.workspace.portlets.AccountPerson;
    },
    adminSettingsPortletId() {
      return this.$store.state.workspace.portlets.SaaSSysInfo;
    },
    topCss() {
      return this.$store.state.workspace.topCss;
    },
    name() {
      return this.$store.state.user.name;
    },
    profileImageUrl() {
      return this.$store.state.user.profileImageUrl;
    },
    isNotificationActive() {
      return this.$store.state.user.authority.isNotificationActive;
    },
    isAdmin() {
      return this.$store.state.user.isAdmin;
    },
    viewUserList() {
      return this.$store.state.user.authority.viewUserList;
    },
    viewSupport() {
      return this.$store.state.user.authority.viewSupport;
    },
    activeApps() {
      return this.$store.state.workspace.activeApps;
    },
    active() {
      return this.$store.state.workspace.active;
    },
    currentTab() {
      return this.$store.state.workspace.currentTab;
    },
    currentContent() {
      return this.$store.state.workspace.currentContent;
    },
    currentParams() {
      return this.$store.state.workspace.currentParams;
    },
    lazyContent() {
      return this.$store.state.workspace.lazyContent;
    },
    badge() {
      return this.$store.state.workspace.badge;
    },
    badgeMobile() {
      const {
        activity, chat, workflow, scheduling,
      } = this.$store.state.workspace.badge;
      let count = 0;
      if (activity) {
        count += activity;
      }
      if (chat) {
        count += chat;
      }
      if (workflow) {
        count += workflow;
      }
      if (scheduling) {
        count += scheduling;
      }
      if (count > 99) {
        count = 99;
      } else if (count === 0) {
        count = null;
      }
      return count;
    },
    fab() {
      return window.toolkit.decodeXml(this.$store.state.workspace.fab);
    },
    headerSubmenu() {
      return this.$store.state.workspace.headerSubmenu;
    },
    headerComponent() {
      return this.$store.state.workspace.headerComponent;
    },
    modal() {
      return this.$store.state.workspace.modal;
    },
    channelIOUnreadCount() {
      return this.$store.state.workspace.channelIOUnreadCount;
    },
    isPresentStatusValid() {
      return this.$store.state.user.authority.isPresentStatusValid;
    },
  },
  mounted() {
    if (typeof MutationObserver !== 'undefined') {
      this.observer = new MutationObserver((mutations) => {
        forEach(mutations, (mutation) => {
          if (!mutation.target.classList.contains('is-visible')) {
            this.drawer = 'system';
          }
        });
      });
      this.observer.observe(document.querySelector('.drawer'), {
        attributes: true,
      });
    }
  },
  methods: {
    onClickLogo() {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistration().then((registration) => {
          registration.update();
          console.log('serviceWorker update.');
        });
      }
      window.location.href = `${this.$store.state.workspace.contextPath}/`;
    },
    onClickSettings() {
      window.App.navigateTo('/portal/settings/');
    },
    onClickActivity() {
      this.drawer = 'activity';
      if (window.ChannelIO) {
        // チャットサポート非表示
        window.ChannelIO('hideMessenger');
      }
      App.openDrawer();
      aipo.menu.activity.reload();
    },
    onClickLogout() {
      this.$store.commit('workspace/pageProgress', true);
      window.location.href = '?action=SaaSJLogoutUser';
    },
    openRightDrawer() {
      this.$store.commit('workspace/topCss', 'rightDrawer');
      const drawer = select('.mdl-layout__drawer.is-right.rightDrawer');
      drawer.classed('is-visible', true);
      const obfuscator = select('.mdl-layout__obfuscator.is-right');
      obfuscator.classed('is-visible', true);
    },
    openCustomRightDrawer() {
      this.$store.commit('workspace/topCss', 'rightDrawer');
      const drawer = select('.mdl-layout__drawer.is-right.customRightDrawer');
      drawer.classed('is-visible', true);
      const obfuscator = select('.mdl-layout__obfuscator.is-right');
      obfuscator.classed('is-visible', true);
    },
    closeRightDrawer() {
      const drawer = selectAll('.mdl-layout__drawer.is-right');
      drawer.classed('is-visible', false);
      const obfuscator = select('.mdl-layout__obfuscator.is-right');
      obfuscator.classed('is-visible', false);
      this.$store.commit('workspace/topCss', '');
    },
    onCloseModal() {
      const { previousPath } = this.$store.state.workspace;
      if (previousPath) {
        window.history.pushState(null, '', previousPath);
        this.$store.commit('workspace/set', { previousPath: '' });
      }
      this.$store.commit('workspace/modal', { component: null, width: '700px' });
    },

    updateMobileWidgets() {
      // ヘッダーのメニューが存在するかどうか
      // ヘッダーに表示するメニューは各テンプレートファイルの#widgetSubmenuで定義される
      let isSubMenuExists = false;
      const menu = select('#header-submenu');
      if (menu.node() && menu.node().MaterialMenu) {
        menu.node().MaterialMenu.hide();
      }
      let menuHtml = '<ul id="header-submenu" class="aui-menu bottom-right" for="header-submenu-button">';
      const submenu = select('#widgetSubmenu');
      if (submenu.node() && submenu.node().innerHTML) {
        menuHtml += submenu.node().innerHTML;
        // ヘッダーのメニューが存在するかどうか
        isSubMenuExists = submenu.node().children.length > 0;
      }
      const settingsMenus = this.$store.state.workspace.options.settingsMenu;
      const { hasMobileSettingsMenu } = this.$store.state.workspace.options;
      const { activeApps, trial } = this.$store.state.workspace;
      Object.keys(settingsMenus).forEach((key) => {
        if (hasMobileSettingsMenu) {
          if (settingsMenus[key].label === '表示設定' || (activeApps.includes('AddOnScheduling') && settingsMenus[key].label === '日程調整')) {
            menuHtml += '<li class="hr"><hr></li>';
            menuHtml += '<li class="item" onClick="App.home().onClickSettingsMobile(';
            menuHtml += key;
            menuHtml += `)">${settingsMenus[key].label}</li>`;
          }
          if (settingsMenus[key].viewKey && trial[settingsMenus[key].viewKey] && trial[settingsMenus[key].viewKey] === 'T') {
            menuHtml += '<li class="item" onClick="App.home().onClickSettingsMobile(';
            menuHtml += key;
            menuHtml += `)">${settingsMenus[key].label}</li>`;
          }
        }
      });
      menuHtml += '</ul>';
      if (!isSubMenuExists) {
        // メニューが存在しない場合はヘッダーにメニューを追加しない
        menuHtml = '';
      }
      this.$store.commit('workspace/headerSubmenu', menuHtml);
      this.$store.commit(
        'workspace/fab',
        select('#widgetFab').node().innerHTML,
      );
      timeout(() => {
        App.upgradeElement();
      }, 10);
    },
    resetMobileWidgets() {
      this.$store.commit('workspace/headerSubmenu', '');
      this.$store.commit('workspace/fab', '');
      timeout(() => {
        App.upgradeElement();
      }, 10);
    },
    onClickSettingsMobile(key) {
      const {
        active,
        portlets,
      } = this.$store.state.workspace;
      const { link, type, func } = this.$store.state.workspace.options.settingsMenu[
        key
      ];
      if (type === 'dialog') {
        let portletId = null;
        if (active === 'MyPage') {
          portletId = type.isAdmin
            ? portlets.SaaSSysInfo
            : portlets.AccountPerson;
        } else if (active && portlets) {
          portletId = portlets[active];
        }
        if (portletId) {
          aipo.common.showDialog(window.toolkit.updateQueryStringParameter(link, 'js_peid', portletId));
        }
      } else if (type === 'page') {
        App.navigateTo(link);
      } else if (type === 'custom') {
        func();
      }
    },
    onClickChangePresence() {
      aipo.common.showDialog('?template=PresenceFormScreen');
    },

  },
};
</script>

<style lang="scss">
</style>
