import Vue from 'vue';
import HomeComponent from './home/containers/home.vue';
import App from '../desktop/app';
import toolkit from '../toolkit';

toolkit.setViewportProperty(document.documentElement);
window.addEventListener('resize', toolkit.setViewportProperty(document.documentElement), true);

window.toolkit = toolkit;
const MobileApp = App.extend({
  render: h => h(HomeComponent),
});
window.Vue = Vue;
window.App = MobileApp;

Vue.component('app', MobileApp);
